import React from "react"
import { graphql } from "gatsby"
import LayoutV2 from "../components/v2/layout-no-padding-site-main"
import SEO from "../components/seo"
import rehypeReact from "rehype-react"

import "../utils/css/components/insights.css"
import { PLink } from "../components/partials/base";

class BlogPostTemplate extends React.Component {
    constructor(props) {
      super(props)
      this.parChild = React.createRef()
      this.parChildDetails = React.createRef()
    }

    render() {
      const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: {
          "title-section": TitleSection,
        },
      }).Compiler
  
      const post = this.props.data.markdownRemark
      const siteTitle = this.props.data.site.siteMetadata.title

      console.log(this.props.pageContext)
  
      return (
        <LayoutV2
          location={this.props.location}
          title={siteTitle}
          headerActive={`Insights`}
        >
          <SEO
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
          />
          <div className="insight-blog-page">
            <TitleSection
                postTitle={post.frontmatter.title}
                postAuthor={post.frontmatter.author}
                postDateCreated={post.frontmatter.date}
            ></TitleSection>
            <article className="post-content page-template animated fadeIn">
                {renderAst(post.htmlAst)}
                <div className="insight-page-prev-next">
                    <PLink to={`insights`} onClickFadeOut={true} ignoreScrollTop={true} style={{fontSize: `20px`}}>
                        Back to Insights
                    </PLink>
                </div>
            </article>
            
          </div>
        </LayoutV2>
      )
    }
  }


class TitleSection extends React.Component {
    render() {  
        const {postTitle, postAuthor, postDateCreated} = this.props;
        return (
            <div className="insight-title-div">
                <div className="insight-title-div--title">
                    {postTitle}
                </div>
                <div className="insight-title-div--author-and-date">
                    <span className="insight-title-div--author-span">
                        By {postAuthor}, 
                    </span>
                    <span className="insight-title-div--date-span">
                        {postDateCreated}
                    </span>
                </div>
            </div>
        )
    }
}  
export default BlogPostTemplate

export const pageQuery = graphql`
  query InsightBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        bgcover
        author
      }
    }
  }
`
